<template>
  <v-dialog
    ref="dialog"
    v-model="modal"
    :return-value.sync="val"
    persistent
    width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="val"
        prepend-inner-icon="mdi-calendar"
        outlined
        readonly
        v-bind="{ ...attrs, ...$attrs , ...input_ }"
        v-on="on"
        hide-details="auto"
      ></v-text-field>
    </template>
    <v-date-picker
      :first-day-of-week="1"
      v-model="val"
      scrollable
      v-bind="{...input_, ...$attrs}"
    >
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="modal = false"> {{$t('common.cancelar')}} </v-btn>
      <v-btn text color="primary" @click="$refs.dialog.save(val)"> {{$t('common.ok')}} </v-btn>
    </v-date-picker>
  </v-dialog>
</template>

<script>
export default {
  props: ["value", "input_"],
  data() {
    return {
      modal: false,
    };
  },
  computed: {
    val: {
      get() {
        // return this.value
        return this.value ? this.value.slice(0, 10) : null;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
  },
};
</script>
